<template>
  <div>
    <RevButton
      v-if="!orderlineAction.hint"
      :data-qa="`orderline-action-${orderlineAction.type}`"
      :disabled="isButtonDisabled"
      full-width="always"
      :loading="isLoading"
      :variant
      @click="performAction"
    >
      {{ orderlineAction.label }}
    </RevButton>

    <RevTooltip v-if="orderlineAction.hint">
      <template #trigger="{ show, hide }">
        <RevButton
          :data-qa="`orderline-action-${orderlineAction.type}`"
          :disabled="isButtonDisabled"
          full-width="always"
          :loading="isLoading"
          :variant
          @click="performAction"
          @focusin="show"
          @focusout="hide"
          @mouseleave="hide"
          @mouseover="show"
        >
          {{ orderlineAction.label }}
        </RevButton>
      </template>

      {{ orderlineAction.hint }}
    </RevTooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { RevButton } from '@ds/components/Button'
import { RevTooltip } from '@ds/components/Tooltip'

import { useOrderlineCardActionsConfig } from '../../../composables/useOrderlineCardActionsConfig'

export interface OrderlineCardActionProps {
  loading?: boolean
  orderlineAction: Orderline['actions'][number]
  orderlineId: Orderline['orderlineId']
  publicId: Orderline['publicId']
  variant: 'primary' | 'secondary'
}

const props = withDefaults(defineProps<OrderlineCardActionProps>(), {
  loading: false,
})

const { config: orderlineCardActionsConfig } = useOrderlineCardActionsConfig()

const config = computed(() =>
  orderlineCardActionsConfig[props.orderlineAction.type](props.orderlineAction),
)

const isLoading = computed(() => {
  return config.value.loading !== undefined
    ? config.value.loading
    : props.loading
})

const isButtonDisabled = computed(
  () => !props.orderlineAction.active || isLoading.value,
)

async function performAction(): Promise<void> {
  if (config.value.onClick) {
    const { onClick } = config.value

    await onClick({
      orderlineId: props.orderlineId,
      publicId: props.publicId,
    })
  }
}
</script>
